import React from "react"
// import PropTypes from "prop-types"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "@material-ui/core/Card"
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from "@material-ui/core/CardContent"
// import CardActions from '@material-ui/core/CardActions';
// import Collapse from '@material-ui/core/Collapse';
// import Avatar from '@material-ui/core/Avatar';
// import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ShareIcon from '@material-ui/icons/Share';


export default class News extends React.Component {

    render() {
        return (
           <Layout>
               <SEO title="News"/>
               <Typography component="h1">
                   News
               </Typography>
               <br/>

               <Card>
                   <CardHeader
                      title="Font selection now available"
                      subheader="February 19, 2020"
                   />
                   <CardContent>
                       <Typography variant="body2" component="p">
                           A new feature now allows you to select the font which will be used to render your countdown. The list of available
                           fonts are fetched from Google Fonts and allows to select between the most 150 popular Fonts on Google.
                       </Typography>
                   </CardContent>
               </Card>

               <br />
               <br />

               <Card>
                   <CardHeader
                      title="New counter available"
                      subheader="January 18, 2020"
                   />
                   <CardContent>
                       <Typography variant="body2" component="p">
                           We proud to announce a new counter type within our app. You're now able to define values for a date and time and
                           the counter will show the remaining days, hours, minutes and seconds.
                           <br/><br/>
                           You can try it <Link to="countdown-to">here</Link>.
                       </Typography>
                   </CardContent>
               </Card>

               <br />
               <br />

               <Card>
                   <CardHeader
                      title="Now define the color within your stream overlay."
                      subheader="January 16, 2020"
                   />
                   <CardContent>
                       <Typography variant="body2" component="p">
                           As of now we're supporting the definition of the colors within the countdowns. This will colorize both the title
                           and the ticker values within the overlay.
                           <br/><br/>
                           Have fun!
                       </Typography>
                   </CardContent>
               </Card>

               <br /><br />

               <Card>
                   <CardHeader
                      title="First Version online"
                      subheader="January 15, 2020"
                   />
                   <CardContent>
                       <Typography component="h3">
                           Yeah! We're online now!
                       </Typography>
                       <br/>
                       <Typography variant="body2" component="p">
                           We're published this simple and small tool to help twitch streamers to easily add an countdown to their streams.
                           <br /><br />
                           For more information about the usage of this tool, goto the <Link to="help">Help Page</Link> to find the required
                           steps to get your Twitch Stream Countdown up and running.
                           <br /><br />
                           If you have any questions or feature-requests, don't hesitate to contact us!
                       </Typography>

                       <Typography variant="body2" component="p">

                       </Typography>
                   </CardContent>
               </Card>
           </Layout>
        )
    }
}